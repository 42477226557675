<template>
  <form @submit.prevent="submit">
    <div class="row">
      <loading-notification :show="loading" />
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-collapse withBackground>
          <span slot="header">{{ $t('layout.filters.label') }}</span>
          <template slot="body">
            <filters
              :loading="loading"
              :actions="filterActions"
              @filter="filterData"
              @clear-filters="filterData(null)"
            />
          </template>
        </va-collapse>
      </div>
      <div class="flex xs12">
        <va-collapse withBackground>
          <span slot="header">{{ $t('notifications.page.addressees') }}</span>
          <template slot="body">
            <remote-table
              class="table-hover table-striped"
              :with-search="false"
              :top-options="[]"
              :columns="tableFields"
              :data="membersList"
              :loading="loading"
              :search="searchOptions"
              :pagination="pagination"
              :queries="queries"
              crud-links="users"
              @search-data="searchByName"
            />
          </template>
        </va-collapse>
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-input
          color="info"
          v-model="form.message.value"
          type="textarea"
          :min-rows="5"
          :autosize="true"
          :disabled="loading"
          :label="$t('layout.form.messageInput')"
          :error="!!form.message.errors.length"
          :error-messages="translatedErrors(form.message.errors)"
          @blur="validate('message')"
        />
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <va-button
          color="primary"
          :disabled="loading"
        >
          <text-loading
            :loading="loading"
            icon="fa fa-save"
          >
            {{ $t('layout.form.save') }}
          </text-loading>
        </va-button>
      </div>
    </div>
  </form>
</template>

<script>
import { validatorMixin } from '@/services/validator'
import { mapGetters } from 'vuex'
const Filters = () => import(/* webpackPrefetch: true */ '@/components/extras/Filters')
const RemoteTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/RemoteTable')

export default {
  name: 'notifications-form',
  components: {
    Filters,
    RemoteTable,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    notification: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        }
      },
    },
  },
  mixins: [validatorMixin],
  computed: {
    ...mapGetters(['currentUser', 'currentLocale']),
    isNew () {
      return this.notification.id === 0
    },
    tableFields () {
      return [
        {
          name: 'name',
          title: this.$t('tables.headings.name'),
          sortField: 'name',
        },
        {
          name: 'country.name',
          title: this.$t('tables.headings.country'),
          sortField: 'Countries.name',
          callback: this.translatedName,
        },
        {
          name: 'email',
          title: this.$t('tables.headings.email'),
          sortField: 'email',
        },
        {
          name: 'group.name',
          title: this.$t('tables.headings.group'),
          sortField: 'Groups.name',
        },
        {
          name: 'dcpi_role.name',
          title: this.$t('tables.headings.dcpi_role'),
          sortField: 'DcpiRoles.name',
        },
      ]
    },
  },
  data () {
    return {
      queries: '',
      noRegionalData: false,
      isLoading: {
        regions: false,
        countries: false,
        districts: false,
        members: false,
        teams: false,
      },
      isError: {
        regions: false,
        countries: false,
        districts: false,
        members: false,
        teams: false,
      },
      membersList: [],
      filterActions: ['regions', 'countries', 'districts', 'groups', 'roles', 'name'],
      pagination: {},
      searchQuery: '',
      searchOptions: {
        enabled: true,
        trigger: 'enter',
        placeholder: this.$t('tables.actions.search'),
      },
      form: {
        message: {
          value: '',
          validate: {
            required: true,
          },
          errors: [],
        },
      },
    }
  },
  watch: {
    notification (val) {
      this.setNotification(val)
    },
    selectedRegion (val) {
      this.checkRegionalData()
    },
    selectedCountry (val) {
      this.checkRegionalData()
    },
    selectedDistrict (val) {
      this.checkRegionalData()
    },
    currentLocale (val) {
      this.validateAll()
    },
    'currentUser.permissions' (val) {
      this.initialData()
    },
  },
  created () {
    // this.initialData()
    if (this.notification) {
      this.setNotification(this.notification)
    }
    this.$nextTick(() => {
      this.validateAll()
    })
  },
  methods: {
    async setNotification (val) {
      this.setFormData(val)
      if (val.user) {
        this.membersList = [val.user]
      }

      if (val.id) {
        await this.$nextTick()
        this.validateAll()
      }
    },
    translatedName (name) {
      return this.$t(name)
    },
    filterData (filters) {
      if (!filters) {
        this.asyncFindMember('')
        return
      }

      let queries = ''
      if (filters.region) {
        queries += '&region=' + filters.region
      }
      if (filters.country) {
        queries += '&country=' + filters.country
      }
      if (filters.district) {
        queries += '&district=' + filters.district
      }
      if (filters.group) {
        queries += '&group=' + filters.group
      }
      if (filters.role) {
        queries += '&role=' + filters.role
      }
      if (filters.name) {
        queries += '&q=' + filters.name
      }

      this.queries = queries
      this.asyncFindMember(queries)
    },
    async searchByName (name) {
      if (name === '') return this.asyncFindMember(this.queries)

      let query = this.queries
      query += '&q=' + name
      this.asyncFindMember(query)
    },
    getTeamLabel (team) {
      const label = `${this.$t('tables.headings.code')}: ${team.code}`

      return label
    },
    checkRegionalData () {
      this.noRegionalData = !this.selectedRegion || !this.selectedCountry || !this.selectedDistrict
    },
    initialData () {
      this.asyncFindMember('')
    },
    async asyncFindMember (query) {
      await this.asyncUsers(query, 'members', 'users')
      if (this.isError.members) {
        this.asyncFindMember(query)
      }
    },
    async asyncUsers (query, type, route) {
      this.isLoading[type] = true
      this.isError[type] = false

      if (!query.includes('&sort=')) {
        query += '&sort=name'
      }
      if (!query.includes('&direction=')) {
        query += '&direction=asc'
      }

      const r = `/${route}?q=${query}`
      let response = false
      try {
        response = await this.$http.get(r)
      } catch (err) {
        this.isLoading[type] = false
        this.isError[type] = true
        return
      }

      this[type + 'List'] = response.data.data || []
      this.pagination = response.data.pagination
      this.isLoading[type] = false
    },
    async submit () {
      this.validateAll()
      if (!this.formReady) return

      const notification = this.getFormData(this.notification)
      const users = this.membersList.map(x => x.id)
      notification.status = 0

      this.assignedError = false
      console.log('notification', notification)
      console.log('users', users)
      this.$emit('submit', { notification, users })
    },
  },
}
</script>
